<template>
    <Banner />

    <Head
        title="vidfaceswap | AI face swaps and deepfakes made easy"
        description="Experience the power of vidfaceswap for effortless AI face swaps and deepfakes with flexible credit packages."
        :appendAppName="false"
        :url="route('landing.home.index')"
        :canonical="route('landing.home.index')"
        :image="{
            url: '/images/og-068742z3inr9.webp',
            type: 'image/webp',
            width: 1200,
            height: 630,
            alt: 'vidfaceswap',
        }"
        keywords="deepfake, face swap, vidfaceswap, AI"
    />

    <div class="bg-gray-900">
        <main>
            <!-- Hero section -->
            <div class="relative isolate overflow-hidden">
                <div
                    class="absolute inset-0 -z-10 h-full bg-[url('/images/ray-02.png')] bg-cover bg-top bg-no-repeat"
                ></div>

                <!-- Header -->
                <Header />

                <div
                    class="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-40 lg:flex lg:items-center lg:px-8"
                >
                    <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:pt-8">
                        <ApplicationLogo class="h-8" variant="light" />

                        <video
                            src="/videos/before-after.mp4"
                            autoplay
                            muted
                            loop
                            class="mt-10 rounded-lg bg-white/5 shadow-2xl shadow-indigo-900 ring-1 ring-white/10 sm:rounded-2xl lg:hidden"
                        ></video>

                        <h1
                            class="mt-6 text-4xl font-bold tracking-tight text-white sm:text-6xl lg:mt-10"
                        >
                            Experience the magic of face swapping
                        </h1>
                        <p class="mt-6 text-lg leading-8 text-gray-300">
                            Transform videos and images effortlessly and create captivating visuals
                            that leave a lasting impression.
                        </p>
                        <div class="mt-10 flex items-center gap-x-6">
                            <a
                                :href="route('app.register')"
                                class="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                            >
                                Get started
                            </a>
                        </div>
                    </div>

                    <video
                        src="/videos/before-after.mp4"
                        autoplay
                        muted
                        loop
                        class="hidden max-w-2xl rounded-3xl bg-white/5 shadow-2xl shadow-indigo-900 ring-1 ring-white/10 lg:ml-10 lg:block"
                    ></video>
                </div>
            </div>

            <!-- How it works section -->
            <div class="mx-auto mt-8 max-w-7xl px-6 sm:mt-16 lg:px-8">
                <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                    <h2 class="text-base font-semibold leading-8 text-indigo-400">How it works</h2>
                    <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                        Create stunning face swaps
                    </p>
                    <p class="mt-6 text-lg leading-8 text-gray-300">
                        Makes the process of creating face swaps as easy as ABC, you can bring your
                        creative vision to life with just a few clicks.
                    </p>
                </div>

                <div
                    class="relative mt-16 space-y-12 sm:grid sm:grid-cols-3 sm:items-center sm:gap-x-6 sm:space-y-0"
                >
                    <div class="absolute inset-0 hidden sm:flex sm:items-center" aria-hidden="true">
                        <div class="h-1 w-full bg-white/20" />
                    </div>

                    <div
                        class="absolute inset-x-0 mx-auto h-4/5 w-1 bg-white/20 sm:hidden"
                        aria-hidden="true"
                    />

                    <div class="relative flex h-full flex-col justify-between">
                        <img
                            src="/images/sample/sample-target.webp"
                            alt="Sample Target"
                            draggable="false"
                            class="rounded-lg bg-white/5 shadow ring-1 ring-white/20 sm:rounded-2xl"
                        />

                        <p class="mt-6 bg-gray-900 text-center text-lg text-gray-400 sm:hidden">
                            Step 1: Upload a target video or image.
                        </p>
                    </div>

                    <div class="relative flex h-full flex-col justify-between">
                        <div
                            class="mx-24 aspect-square flex-shrink-0 rounded-full bg-cover bg-center bg-no-repeat ring-1 ring-white/20 sm:mx-10 lg:mx-14 xl:mx-20"
                            style="background-image: url('/images/sample/sample-source.webp')"
                        ></div>

                        <p class="mt-6 bg-gray-900 text-center text-lg text-gray-400 sm:hidden">
                            Step 2: Upload the face to swap.
                        </p>
                    </div>

                    <div class="relative flex h-full flex-col justify-between">
                        <img
                            src="/images/sample/sample-result.webp"
                            alt="Sample Result"
                            draggable="false"
                            class="rounded-lg bg-white/5 shadow-2xl shadow-indigo-900 ring-1 ring-white/20 sm:rounded-2xl"
                        />

                        <p class="mt-6 text-center text-lg text-gray-400 sm:hidden">
                            That's all! Experience the magic, get your result.
                        </p>
                    </div>
                </div>

                <div class="mt-6 hidden grid-cols-3 gap-x-6 sm:grid">
                    <p class="text-lg text-gray-400">Step 1: Upload a target video or image.</p>

                    <p class="text-center text-lg text-gray-400">
                        Step 2: Upload the face to swap.
                    </p>

                    <p class="text-lg text-gray-400">
                        That's all! Experience the magic, get your result.
                    </p>
                </div>
            </div>

            <!-- Feature section -->
            <div class="mx-auto mt-32 max-w-7xl px-6 sm:mt-56 lg:px-8">
                <div class="mx-auto max-w-2xl text-center">
                    <h2 class="text-base font-semibold leading-7 text-indigo-400">
                        Unlock the potential
                    </h2>
                    <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                        Empower your creativity with us
                    </p>
                    <p class="mt-6 text-lg leading-8 text-gray-300">
                        We offer a world of possibilities to enhance your content creation.
                        Experience the full spectrum of our capabilities and see how we can elevate
                        your projects with ease.
                    </p>
                </div>
                <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                    <dl
                        class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3"
                    >
                        <div
                            v-for="feature in primaryFeatures"
                            :key="feature.name"
                            class="flex flex-col"
                        >
                            <dt class="text-base font-semibold leading-7 text-white">
                                <div
                                    class="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-500"
                                >
                                    <component
                                        :is="feature.icon"
                                        class="h-6 w-6 text-white"
                                        aria-hidden="true"
                                    />
                                </div>
                                {{ feature.name }}
                            </dt>
                            <dd
                                class="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-300"
                            >
                                <p class="flex-auto">{{ feature.description }}</p>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>

            <!-- Pricing section -->
            <div id="pricing" class="mx-auto mt-32 max-w-7xl scroll-mt-20 px-6 sm:mt-56 lg:px-8">
                <div class="flex flex-col-reverse lg:flex-row">
                    <div class="mx-auto mt-8 lg:mt-0">
                        <PackageRadioGroup :packages="packages" v-model="selectedPackage" />
                    </div>

                    <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                        <h2 class="text-base font-semibold leading-8 text-indigo-400">
                            Flexible credit pricing
                        </h2>
                        <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                            Pricing that fits your needs
                        </p>
                        <p class="mt-6 text-lg leading-8 text-gray-300">
                            Get started with the perfect credit package today and unlock unlimited
                            creative potential.
                        </p>
                        <p class="mt-6 text-lg leading-8 text-gray-300">
                            Each credit allows you to swap 1 second of video, with a minimum of
                            {{ minCreditsPerSwap }} credits required per swap.
                        </p>
                        <p class="mt-6 text-lg leading-8 text-gray-300">
                            And don't forget, we also offer {{ freeImagePerDay }} free swapping for
                            images per day that lets you experience video face swapping with images
                            at no cost. Plus, you can earn free credits through our
                            <Link
                                :href="route('landing.referral-program.index')"
                                class="text-indigo-400 hover:text-indigo-300"
                                >referral program</Link
                            >. Try it out and see the magic for yourself.
                        </p>
                    </div>
                </div>
            </div>

            <!-- CTA section -->
            <div class="relative isolate mt-8 px-6 py-32 sm:mt-16 sm:py-40 lg:px-8">
                <svg
                    class="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="1d4240dd-898f-445f-932d-e2872fd12de3"
                            width="200"
                            height="200"
                            x="50%"
                            y="0"
                            patternUnits="userSpaceOnUse"
                        >
                            <path d="M.5 200V.5H200" fill="none" />
                        </pattern>
                    </defs>
                    <svg x="50%" y="0" class="overflow-visible fill-gray-800/20">
                        <path
                            d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                            stroke-width="0"
                        />
                    </svg>
                    <rect
                        width="100%"
                        height="100%"
                        stroke-width="0"
                        fill="url(#1d4240dd-898f-445f-932d-e2872fd12de3)"
                    />
                </svg>
                <div
                    class="absolute inset-x-0 top-10 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
                    aria-hidden="true"
                >
                    <div
                        class="h-96 w-full flex-none bg-gradient-to-r from-[#312e81] to-[#4f46e5] opacity-20"
                        style="
                            clip-path: polygon(
                                73.6% 51.7%,
                                91.7% 11.8%,
                                100% 46.4%,
                                97.4% 82.2%,
                                92.5% 84.9%,
                                75.7% 64%,
                                55.3% 47.5%,
                                46.5% 49.4%,
                                45% 62.9%,
                                50.3% 87.2%,
                                21.3% 64.1%,
                                0.1% 100%,
                                5.4% 51.1%,
                                21.4% 63.9%,
                                58.9% 0.2%,
                                73.6% 51.7%
                            );
                        "
                    />
                </div>
                <div class="mx-auto max-w-2xl text-center">
                    <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                        Ready to get started?
                    </h2>
                    <p class="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
                        Join us and experience the power of face swapping like never before. Elevate
                        your content with ease, and let your creativity shine.
                    </p>
                    <div class="mt-10 flex items-center justify-center gap-x-6">
                        <a
                            :href="route('app.register')"
                            class="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                            >Sign up now</a
                        >
                    </div>
                </div>
            </div>
        </main>

        <!-- Footer -->
        <footer aria-labelledby="footer-heading" class="relative">
            <h2 id="footer-heading" class="sr-only">Footer</h2>
            <div class="mx-auto max-w-7xl px-6 pb-8 pt-4 lg:px-8">
                <div class="xl:grid xl:grid-cols-3 xl:gap-8">
                    <div class="grid grid-cols-2 gap-8 xl:col-span-2">
                        <div>
                            <h3 class="text-sm font-semibold leading-6 text-white">Company</h3>
                            <ul role="list" class="mt-6 space-y-4">
                                <li>
                                    <a
                                        :href="route('app.dashboard.index')"
                                        class="text-sm leading-6 text-gray-300 hover:text-white"
                                        >App</a
                                    >
                                </li>
                                <li>
                                    <a
                                        :href="route('blog.posts.index')"
                                        class="text-sm leading-6 text-gray-300 hover:text-white"
                                        >Blog</a
                                    >
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h3 class="text-sm font-semibold leading-6 text-white">Legal</h3>
                            <ul role="list" class="mt-6 space-y-4">
                                <li>
                                    <Link
                                        :href="route('landing.legal.terms-of-service.index')"
                                        class="text-sm leading-6 text-gray-300 hover:text-white"
                                        >Terms of Service</Link
                                    >
                                </li>
                                <li>
                                    <Link
                                        :href="route('landing.legal.privacy-policy.index')"
                                        class="text-sm leading-6 text-gray-300 hover:text-white"
                                        >Privacy Policy</Link
                                    >
                                </li>
                                <li>
                                    <Link
                                        :href="route('landing.legal.refund-policy.index')"
                                        class="text-sm leading-6 text-gray-300 hover:text-white"
                                        >Refund Policy</Link
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div
                    class="mt-16 border-t border-white/10 pt-8 sm:mt-20 md:flex md:items-center md:justify-between lg:mt-24"
                >
                    <div class="flex space-x-6 md:order-2">
                        <a
                            v-for="item in footerNavigation.social"
                            :key="item.name"
                            :href="item.href"
                            class="text-gray-500 hover:text-gray-400"
                        >
                            <span class="sr-only">{{ item.name }}</span>
                            <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                        </a>
                    </div>
                    <p class="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">
                        &copy; {{ new Date().getFullYear() }} vidfaceswap. All rights reserved.
                    </p>
                </div>
            </div>
        </footer>
    </div>
</template>

<script setup>
import ApplicationLogo from '@/Components/ApplicationLogo.vue'
import Banner from '@/Landing/Pages/Home/Partials/Banner.vue'
import { defineComponent, h, ref } from 'vue'
import {
    EnvelopeIcon,
    FaceSmileIcon,
    ShieldCheckIcon,
    SparklesIcon,
} from '@heroicons/vue/24/outline'
import Head from '@/Components/Head.vue'
import Header from '@/Landing/Pages/Home/Partials/Header.vue'
import { Link } from '@inertiajs/vue3'
import PackageRadioGroup from '@/Landing/Pages/Home/Partials/PackageRadioGroup.vue'

const primaryFeatures = [
    {
        name: 'High-quality results',
        description:
            'Experience seamless and precise face swapping technology that effortlessly transforms your videos and images.',
        icon: SparklesIcon,
    },
    {
        name: 'Privacy-focused approach',
        description:
            'Your privacy is our priority. We employ a privacy-focused approach, safeguarding your data and ensuring a secure and confidential experience.',
        icon: ShieldCheckIcon,
    },
    {
        name: 'User-friendly interface',
        description:
            "Our user-friendly interface is designed for simplicity and ease of use. Whether you're a beginner or an expert, you'll navigate our platform effortlessly.",
        icon: FaceSmileIcon,
    },
]

defineProps({
    packages: {
        type: Object,
        required: true,
    },
    minCreditsPerSwap: {
        type: Number,
        required: true,
    },
    freeImagePerDay: {
        type: Number,
        required: true,
    },
})

const selectedPackage = ref({})

const footerNavigation = {
    social: [
        {
            name: 'Email',
            href: 'mailto:hello@vidfaceswap.com',
            icon: EnvelopeIcon,
        },
        // {
        //     name: 'Facebook',
        //     href: '#',
        //     icon: defineComponent({
        //         render: () =>
        //             h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
        //                 h('path', {
        //                     'fill-rule': 'evenodd',
        //                     d: 'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
        //                     'clip-rule': 'evenodd',
        //                 }),
        //             ]),
        //     }),
        // },
        // {
        //     name: 'Instagram',
        //     href: '#',
        //     icon: defineComponent({
        //         render: () =>
        //             h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
        //                 h('path', {
        //                     'fill-rule': 'evenodd',
        //                     d: 'M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z',
        //                     'clip-rule': 'evenodd',
        //                 }),
        //             ]),
        //     }),
        // },
        // {
        //     name: 'Twitter',
        //     href: '#',
        //     icon: defineComponent({
        //         render: () =>
        //             h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
        //                 h('path', {
        //                     d: 'M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84',
        //                 }),
        //             ]),
        //     }),
        // },
        // {
        //     name: 'GitHub',
        //     href: '#',
        //     icon: defineComponent({
        //         render: () =>
        //             h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
        //                 h('path', {
        //                     'fill-rule': 'evenodd',
        //                     d: 'M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z',
        //                     'clip-rule': 'evenodd',
        //                 }),
        //             ]),
        //     }),
        // },
        // {
        //     name: 'YouTube',
        //     href: '#',
        //     icon: defineComponent({
        //         render: () =>
        //             h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
        //                 h('path', {
        //                     'fill-rule': 'evenodd',
        //                     d: 'M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z',
        //                     'clip-rule': 'evenodd',
        //                 }),
        //             ]),
        //     }),
        // },
    ],
}
</script>
